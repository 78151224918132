
  .offer-details {
    ul {
      list-style-type: none;
      padding: 0;
      li {
        h3 {
          font-size: 1.6em;
          margin-bottom: 10px;
        }
        margin-bottom: 10px;
        ul {
          margin-bottom: 20px;
          li {
            .label {
              font-weight: bold;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
