
  @import '../../../node_modules/bootstrap/dist/css/bootstrap';
  @import '../../../node_modules/bootstrap-vue-next/dist/bootstrap-vue-next';
  .licenses {
    li {
      font-size: 1.3em;
      i.bi {
        margin-right: 10px;
      }
    }
  }
  ul {
    list-style-type: none;
    padding-left: 10px;
  }
  .features {
    margin-top: 20px;
  }
  .features, .other-features-and-hazards {
    margin-bottom: 10px;
  }
  h2 {
    font-size: 1.8em;
  }
  h2,h3 {
    margin-bottom: 15px;
  }
  h3 {
    font-size: 1.5em;
  }
  .other-features-and-hazards {
    ul {
      list-style-type: none;
      padding-left: 20px;
    }
  }
