.multiselect {
  margin-top: 0 !important;
  border: var(--bs-border-width) solid rgba(0, 0, 0, 0.14) !important;
  border-radius: var(--bs-border-radius);
}
.multiselect__option--selected.multiselect__option--highlight {
  background-color: white !important;
  color: black;
  font-weight: bold;
}
.multiselect__content {
  padding-left: 0 !important;
}

.multiselect--active:not(.multiselect--above) .multiselect__current, .multiselect--active:not(.multiselect--above) .multiselect__input, .multiselect--active:not(.multiselect--above) .multiselect__tags {
  border: none !important;
}

.input-group-text {
  opacity: 0.7;
  cursor: pointer !important;
}

.input-group-text :hover {
  font-weight: bold;
  background-color: rgb(77, 77, 77) !important;
  color: rgb(192, 192, 192) !important;
}

.actions_visible {
  visibility: visible !important;
  opacity: 1;
  transition: opacity 0.6s linear;
}

.actions_hidden {
  opacity: 0.6;
  transition: visibility 0s 0.6s, opacity 0.6s linear;
}

.padlock_hidden {
  display: none;
  -webkit-transition: background-color 1.5s ease-out;
  -moz-transition: background-color 1.5s ease-out;
  -o-transition: background-color 1.5s ease-out;
  transition: background-color 1.5s ease-out;
}

.spinner-border {
  --bs-spinner-width: 0.9em !important;
  --bs-spinner-height: 0.9em !important;
  --bs-spinner-border-width: 0.15em !important;
  border-color: rgb(144, 0, 0) !important;
  border-right-color: transparent !important;
  margin-right: 12px;
}

.not_found {
  font-size: 12px;
  color: rgb(144, 0, 0);
}

datalist {
  position: absolute;
  margin-top: 2.6em !important;
  max-height: 20em;
  width: auto !important;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1000;
  border: 1px solid #e8e8e8 !important;
}

.menu_left {
  left: auto !important;
  right: 0 !important;
}

.menu_right {
  right: auto !important; 
  left: 0 !important;
}

datalist option {
  width: 100%;
  background-color: white;
  cursor: pointer;
  font-size: 16px;
  padding: 0.9em;
}

/* option active styles */
datalist option:hover, datalist option:focus {
  color: #fff;
  background-color: #41b883;
  outline: 0 none;
}
