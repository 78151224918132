
  :deep() {
    @import '../../../node_modules/bootstrap/scss/bootstrap.scss';
    @import '../../../node_modules/bootstrap-vue-next/dist/bootstrap-vue-next';
  }

  input[type="date"]:before {
    display: none !important;
  }

  input[type="date"]:focus:before {
    display: none !important;
  }
