

  :deep() {
    .tag-green {
      background-color: #28a745 !important;
    }
    .tag-red {
      background-color: #dc3545 !important;
    }
    label {
      font-weight: bold;
    }
  }

