
  :deep() {
    .accessibility_features {
      a {
        text-decoration: none;
      }
    }
    .modal {
      all: unset;
    }
    @import '../../../../node_modules/bootstrap/dist/css/bootstrap';
    @import '../../../../node_modules/bootstrap-vue-next/dist/bootstrap-vue-next';
  }
  .cover {
    max-width: 150px;
    display: block;
  }
  :deep(.table) {
    margin: 0 !important;
  }
  .link-disable {
    color: #6c757d;
    pointer-events: none;
    text-decoration: none;
  }
  .order-columns {
    border: 3px solid #ccc;
    padding: 20px;
    margin: 20px 0;
    position: relative;
    button.close {
      background: transparent;
      border: none;
      color: #777;
      &:hover {
        color: #000;
      }
      i {
        font-size: 2em;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
    .order-confirm-or-cancel-buttons {
      button {
        margin: 0 10px;
      }
    }
    :deep(.columns-dropdown .dropdown-menu ul li) {
      padding: 10px !important;
    }
    ul {
      margin: 30px;
      padding: 0;
      list-style: none;
      li {
        border-radius: 5px;
        padding: 5px 15px;
        font-weight: semi-bold;
        font-size: 1.1em;
        display: flex;
        height: 40px;
        justify-content: space-between;
        align-items: center;
        .icon-eye {
          margin-right: 10px;
          position: relative;
          span {
            position: absolute;
            height: 23px;
            width: 2px;
            rotate: 45deg;
            left: 7px;
            display: block;
            opacity: 0.8;
            background-color: #3c93df;
          }
        }
        button {
          border: none;
          cursor: pointer;
        }
      }
    }
    .inactive-columns {
      li {
        color: #3c93df;
        border: 1px solid #6baee9;
        .icon-eye {
          margin-right: 10px;
        }
        button {
          background-color: transparent;
          color: #fff;
          &:hover {
            color: #000;
          }
        }
      }
    }
    .positions {
      li {
        background-color: #3c93df;
        color: #fff;
        button {
          background-color: transparent;
          color: #fff;
          &:hover {
            color: #000;
          }
        }
        .icon-eye {
          span {
            display: none;
          }
          &:hover {
            span {
              display: block;
              background-color: #fff;
            }
          }
        }
      }
    }
  }
  :deep(.button-columns-change) {
    color: #3c93df !important;
    border-color: #6baee9 !important;
    background-color: #fff !important;
    &:hover {
      color: #fff !important;
      background-color: #3c93df !important;
    }
  }
  :deep() {
    .table-responsive-sm {
      display: block;
      overflow-x: auto;
      tbody, thead {
        .table-column {
          min-width: 190px;
          max-width: 250px;
        }
        th, td {
          &:first-child, &:nth-child(2), &:nth-child(3) {
            position: sticky;
            z-index: 3;
          }
          &:first-child {
            left: 0;
          }
          &:nth-child(2) {
            left: 30px;
          }
          &:nth-child(3) {
            left: 199px;
          }
        }
      }
      tbody {
        tr {
          max-height: 242px;
        }
      }
    }
  }
  .content-text {
    display: block;
  }
  .link-default {
    text-decoration: none;
    &:hover {
      color: #155995;
    }
  }
  .custom-menu {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        a {
          display: block;
          padding: 8px 12px;
          cursor: pointer;
          color: #333;
          text-decoration: none;
          &:hover {
            background-color: #f8f9fa;
          }
        }
      }
    }
  }
  .dropdown-actions {
    margin-left: 30px;
    :deep(.disabled) {
      color: #ccc !important;
      b {
        color: #000 !important;
      }
    }
  }
  :deep(.table-header-tr) {
    th {
      background-color: #fff;
      i {
        position: relative;
        z-index: 1;
      }
    }
  }
  .box-isbns {
    margin: 20px 0;
    .btn-link {
      color: #000;
      text-decoration: none;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 10px 0 0 0;
      li {
        padding: 5px;
      }
    }
  }
