
  :deep() {
    .modal {
      all: unset;
    }
    @import '../../../node_modules/bootstrap/dist/css/bootstrap';
    @import '../../../node_modules/bootstrap-vue-next/dist/bootstrap-vue-next';
    .table {
      margin: 0 !important;
      .removing {
        opacity: 0.0;
        animation: fadeInOut 2s ease-in-out infinite;
      }
    }

    @keyframes fadeInOut {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 0.3;
      }
      100% {
        opacity: 0;
      }
    }
  }
