

  .ghost {
    opacity: 0.4;
  }

  .with_border {
    border: 1px solid #ccc;
  }

  .order-columns {
    padding: 16px;
    position: relative;
    button.close {
      background: transparent;
      border: none;
      color: #777;
      &:hover {
        color: #000;
      }
      i {
        font-size: 2em;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
    .order-confirm-or-cancel-buttons {
      button {
        margin: 0 10px;
      }
    }
    :deep(.columns-dropdown .dropdown-menu ul li) {
      padding: 10px !important;
    }
    ul {
      list-style: none;
      cursor: move;
      li {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
        border-radius: 5px;
        padding: 0px 15px;
        font-weight: semi-bold;
        font-size: 1em;
        display: flex;
        height: 32px;
        justify-content: space-between;
        align-items: center;
        .icon-eye {
          margin-right: 10px;
          position: relative;
          span {
            position: absolute;
            height: 23px;
            width: 2px;
            rotate: 45deg;
            left: 7px;
            display: block;
            opacity: 0.8;
            background-color: #3c93df;
          }
        }
        button {
          border: none;
          cursor: pointer;
        }
      }
    }
    .inactive-columns {
      li {
        color: #3c93df;
        border: 1px solid #6baee9;
        .icon-eye {
          margin-right: 10px;
        }
        button {
          background-color: transparent;
          color: #fff;
          &:hover {
            color: #000;
          }
        }
      }
    }
    .positions {
      li {
        background-color: #3c93df;
        color: #fff;
        button {
          background-color: transparent;
          color: #fff;
          &:hover {
            color: #000;
          }
        }
        .icon-eye {
          span {
            display: none;
          }
          &:hover {
            span {
              display: block;
              background-color: #fff;
            }
          }
        }
      }
    }
  }
