//// Material Design Components ////
  // This uses the 'Material Design Components' library for the web

  //    compontents:   https://material-components.github.io/material-components-web-catalog/#/
  //    customization: https://material.io/develop/web/components/theme/


// variable overrides:
//       https://material.io/develop/web/components/theme/#usage

$mdc-theme-primary: #3487ae;
$mdc-theme-secondary: #245f7b;
$mdc-text-field-focused-label-color: #245f7b;

// Import Component Styles    (add with yarn, first ;)
//                              (`yarn add @material/textfield`)
@import "@material/textfield/mdc-text-field";
@import "@material/form-field/mdc-form-field";
@import "@material/checkbox/mdc-checkbox";
@import "@material/radio/mdc-radio";

@import "@material/list/mdc-list";
@import "@material/menu-surface/mdc-menu-surface";
@import "@material/menu/mdc-menu";
@import "@material/select/mdc-select";
@import "@material/data-table/mdc-data-table";
@import "@material/snackbar/mdc-snackbar";

// Override backgroung images for selects, because our CSP now prevent images as inline data
.mdc-select__dropdown-icon {
  background-image: url('select.svg');
}
.mdc-select--focused .mdc-select__dropdown-icon {
  background-image: url('select-active.svg');
}

.center-my-children {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.two-columns-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.buttons-line {
  display: flex;
  flex-direction: row;
  align-items: center;

  * {
    margin-right: 1em;

    &:last-child {
      margin-right: 0;
    }
  }
}

.centered-child {
  width: 100%;
  max-width: 650px;

  &--large {
    max-width: 100%;
  }

  &--medium {
    max-width: 850px;
  }
}

.centered-child--small {
  width: 100%;
  max-width: 400px;
}

.container-large {
  width: 100%;
  max-width: 1000px;
}

.example-form {
  max-width: 650px;
}

.mdc-floating-label {
  // mdc-floating-label-ink-color(#)
}

.mdc-text-field.mdc-text-field--textarea {
  background-color: #ffffff;
}


//// Form Styles ////

  // - These forms take the width of the element they're put into.
  // - Children of .form-row will share the space equally, but have a minumum
  //   width after which they'll move to their own row.

  // Note: once chromium has the flex-gap property, we can just use that for the
  //    space between the items: https://github.com/w3c/csswg-drafts/issues/1696

$space-between-inputs: 1.5rem; // For both horizontal and vertical

.form-container {
  overflow-x: hidden;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: calc(#{var(--side-margin)} * -1);

  // to stack the inputs in a row, add this class to the row
  &--stacked {
    flex-direction: column;
    padding-bottom: $space-between-inputs;
    align-items: stretch;

    .form-field-container {
      margin-bottom: 0;
    }

    .filtrable-select {
      margin-left: 60px;
    }
  }

  &--input-file {
    position: relative;
    justify-content: flex-start;
    margin: 0 0 24px;

    button:hover + .file-type-content {
      border-color: var(--primary);
      color: $mdc-theme-secondary;
    }
  }

  &--inline {
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
  }

  &--inline-compact {
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;

    .form-field-container {
      min-width: 0;
      flex: 0 1 auto;
    }

    .mdc-hidden-input {
      width: 1px;
    }

    .button {
      height: 100%;
    }
  }
}

.form-field-container {
  flex: auto;
  min-width: 18rem;
  margin: 0 0 24px var(--side-margin);
}

.form-row--inline-compact {
  .form-field-container:not(:first-child) {
    margin-left: var(--small-side-margin);
  }
}

.full-width {
  width: 100%;
}

.half-width {
  width: 46.5%;
}

.mdc-hidden-input {
  opacity: 0;
  float: left;
  box-sizing: border-box;
  width: 100%;
  height: 1px;
  border: 0;
  padding: 0;
}

input[type="file"] {
  display: none;
}

.file-type-content {
  margin-left: -3px;
  margin-top: 0px;
  padding: 0 10px;
  width: auto;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  border: 0.125rem solid var(--light-gray-2);
  border-left-style: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  &:empty {
    display: none;
  }
}

.cart-list__menu {
  & h3 {
    font-family: var(--font-family);
    font-size: 0.875rem;
    font-weight: 600;
    margin: 0.75rem 0.75rem 0.5rem 0.75rem;
  }

  & li:not(.mdc-list-divider) {
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: 400;
    height: 2.2rem;
    padding-left: 2rem;
  }
}

.mdc-data-table {
  width: 100%;
}
