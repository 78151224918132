.menu-actions {
  &__content {
    margin-top: calc(2.75rem - 6px);
  }

  &__items {
    min-width: 124px;
    border-radius: 0;
  }

  &__list {
    padding: 0;
  }

  &__item {
    padding: 0;
    height: 36px;
  }

  &__link {
    padding: 0 16px;
    display: block;
    color: #000000;
    text-decoration: none;
    white-space: nowrap;
    width: 100%;
    font-family: var(--font-family);
  }

  &__form {
    width: 100%;
    height: 100%;

    &-button {
      display: block;
      width: 100%;
      height: 100%;
      padding: 0 16px;
      font-family: var(--font-family);
      font-size: 1rem;
      text-align: left;
      border: none;
      cursor: pointer;
      background-color: var(--background);

      &--white {
        background-color: #ffffff;
      }
    }
  }
}
