
  :deep() {
    @import '../../../node_modules/bootstrap/dist/css/bootstrap';
    @import '../../../node_modules/bootstrap-vue-next/dist/bootstrap-vue-next';
  }

  :deep(.custom-offcanvas) {
    min-height: 400px !important;
  }
  .title {
    color: black;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 18px;
  }
  .form-check-input {
    border-color: gray;
  }
