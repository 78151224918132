
  .multiselect__tags {
    border: none;
  }

  .include {
    .multiselect__tag,
    .multiselect__option--highlight {
      background-color: #27AE60;
    }
  }

  .exclude {
    .multiselect__tag,
    .multiselect__option--highlight {
      background-color: #C0392B;
    }
  }

  .multiselect__tag-icon::after {
    color: white;
  }
