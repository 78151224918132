
  :deep(){
    .btn-active {
      .bi-eye-slash-fill {
        display: none;
      }
      &:hover {
        background-color: #fff !important;
        color: #0d6efd !important;
        .bi-eye-fill {
          display: none;
        }
        .bi-eye-slash-fill {
          display: inline;
        }
      }
    }
    .btn-inactive {
      .bi-eye-fill {
        display: none;
      }
      &:hover {
        .bi-eye-slash-fill {
          display: none;
        }
        .bi-eye-fill {
          display: inline;
        }
      }
    }
  }
  ul {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    align-items: start;
    li {
      margin: 5px;
    }
  }
